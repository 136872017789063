export interface PersonChange {
  op: "ADDED" | "REMOVED";
  func: "BOARD" | "CEO" | "CHAIRMAN";
  name: string;
}

const getString = (person: PersonChange): string => {
  switch (person.func) {
    case "BOARD":
      if (person.op === "ADDED") {
        return `${person.name} er indtrådt i bestyrelsen`;
      } else {
        return `${person.name} er udtrådt af bestyrelsen`;
      }
    case "CHAIRMAN":
      if (person.op === "ADDED") {
        return `${person.name} er blevet bestyrelsesformand`;
      } else {
        return `${person.name} er ikke længere bestyrelsesformand`;
      }
    case "CEO":
      if (person.op === "ADDED") {
        return `${person.name} er blevet direktør`;
      } else {
        return `${person.name} er ikke længere direktør`;
      }
    default:
      return "";
  }
};

export const Persons = ({
  persons,
}: {
  persons: PersonChange[];
}): JSX.Element => {
  return (
    <div>
      {persons.map((person) => (
        <div>{getString(person)}</div>
      ))}
    </div>
  );
};
