import React, { useCallback, useState } from "react";
import {
  Stack,
  IStackTokens,
  DefaultButton,
  SearchBox,
  DatePicker,
  IDatePickerStrings,
} from "@fluentui/react";
import "./App.css";
import useAxios from "axios-hooks";
import { Logo } from "./logo";
import moment from "moment";
import { Box } from "@mui/system";
import {
  AppBar,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  Paper,
  Toolbar,
} from "@mui/material";
import { groupNamesByFunction, UpdateData } from "./utils/functions-group";
import { PersonChange, Persons } from "./persons";

const stackTokens: IStackTokens = { childrenGap: 15 };

enum UpdateType {
  NAME = "NAME",
  STATUS = "STATUS",
  ADDRESS = "ADDRESS",
  NOOWNER = "NOOWNER",
  NEWCOMPANY = "NEWCOMPANY",
  PERSONS = "PERSONS",
}

const UPDATE_TYPE_LABELS = {
  [UpdateType.NAME]: "Navneændring",
  [UpdateType.STATUS]: "Statusændring",
  [UpdateType.ADDRESS]: "Addresseændring",
  [UpdateType.NOOWNER]: "Ingen reel ejer",
  [UpdateType.NEWCOMPANY]: "Nye virksomheder",
  [UpdateType.PERSONS]: "Personændringer",
};

export const App: React.FunctionComponent = () => {
  const [take] = useState(20);
  const [skip, setSkip] = useState(0);
  const [search, setSearch] = useState("");
  const [toDate, setToDate] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [onlyCorp, setOnlyCorp] = useState(true);
  const [checkedTypes, setCheckedTypes] = useState<Record<UpdateType, boolean>>(
    {
      [UpdateType.NAME]: true,
      [UpdateType.STATUS]: true,
      [UpdateType.ADDRESS]: false,
      [UpdateType.NOOWNER]: true,
      [UpdateType.NEWCOMPANY]: true,
      [UpdateType.PERSONS]: true,
    }
  );

  const [{ data, loading }, refetch] = useAxios<{
    count: number;
    statuses: (
      | {
          id: number;
          oldValue: string;
          newValue: string;
          type: UpdateType;
          company: { name: string };
          companyId: number;
          updatedAt: string;
        }
      | {
          id: number;
          oldValue: null;
          newValue: UpdateData;
          type: UpdateType.NEWCOMPANY;
          company: { name: string };
          companyId: number;
          updatedAt: string;
        }
    )[];
  }>(
    `/api/status?take=${take}&skip=${skip}&search=${encodeURIComponent(
      search
    )}&fromDate=${fromDate}&toDate=${toDate}${Object.keys(checkedTypes)
      .filter((key) => checkedTypes[key as UpdateType])
      .map((key) => `&types[]=${key}`)
      .join("")}${onlyCorp ? "&onlyCorp=true" : ""}`
  );

  const handleNextClick = useCallback(() => {
    setSkip(skip + take);
    refetch();
    window.scrollTo(0, 0);
  }, [refetch, skip, take]);
  const handlePrevClick = useCallback(() => {
    setSkip(skip - take);
    refetch();
    window.scrollTo(0, 0);
  }, [refetch, skip, take]);

  const onSearch = useCallback(
    (searchVal: string) => {
      setSearch(searchVal);
      setSkip(0);
      refetch();
    },
    [refetch]
  );
  console.log(search);
  const dayPickerStrings: IDatePickerStrings = {
    months: [
      "Januar",
      "Februar",
      "Marts",
      "April",
      "MaJ",
      "Juni",
      "July",
      "August",
      "September",
      "Oktober",
      "November",
      "December",
    ],

    shortMonths: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "Maj",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Okt",
      "Nov",
      "Dec",
    ],

    days: [
      "Søndag",
      "Mandag",
      "Tirsdag",
      "Onsdag",
      "Torsdag",
      "Fredag",
      "Lørdag",
    ],

    shortDays: ["S", "M", "T", "O", "T", "F", "L"],

    goToToday: "Go til idag",
    prevMonthAriaLabel: "Go til forrige måned",
    nextMonthAriaLabel: "Go til næste måned",
    prevYearAriaLabel: "Go til forrige år",
    nextYearAriaLabel: "Go til næste år",
  };
  const onFromDateChange = useCallback(
    (date) => setFromDate(moment(date).format("YYYY-MM-DD")),
    []
  );
  const onToDateChange = useCallback(
    (date) => setToDate(moment(date).format("YYYY-MM-DD")),
    []
  );

  const formatDate = useCallback((date) => {
    return moment(date).format("DD / MM / YYYY");
  }, []);

  const handleOnlyCorpChange = useCallback(
    (event) => {
      setOnlyCorp(event.target.checked);
      setSkip(0);
      refetch();
    },
    [refetch]
  );

  const handleUpdateTypesChangesChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setCheckedTypes({
        ...checkedTypes,
        [event.target.name]: event.target.checked,
      });
    },
    [checkedTypes]
  );

  return (
    <div style={{ fontFamily: "IBM Plex Sans,system" }}>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static">
          <Toolbar style={{ backgroundColor: "#FFF" }}>
            <Logo />
          </Toolbar>
        </AppBar>
      </Box>
      <Grid container style={{ maxWidth: 1280, margin: "auto" }}>
        <Grid item sm={3}>
          <Paper square style={{ padding: "1em", margin: "1em" }}>
            <div style={{ fontWeight: "bold", fontSize: 24 }}>Filter</div>
            <SearchBox
              placeholder="Søg"
              styles={{ root: { width: "100%" } }}
              onSearch={onSearch}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={onlyCorp}
                  onChange={handleOnlyCorpChange}
                  color="primary"
                />
              }
              label="Kun selskaber"
            />
            <DatePicker
              styles={{ textField: { width: "12em" } }}
              label="Fra dato "
              ariaLabel="Fra dato"
              strings={dayPickerStrings}
              value={fromDate ? new Date(fromDate) : undefined}
              onSelectDate={onFromDateChange}
              formatDate={formatDate}
            />
            <DatePicker
              styles={{ textField: { width: "12em" } }}
              label="Til dato"
              ariaLabel="Til dato"
              strings={dayPickerStrings}
              value={toDate ? new Date(toDate) : undefined}
              onSelectDate={onToDateChange}
              formatDate={formatDate}
            />
            <strong>Opdateringer</strong>
            {Object.values(UpdateType).map((updateType) => (
              <FormControlLabel
                key={updateType}
                style={{ display: "block" }}
                control={
                  <Checkbox
                    checked={checkedTypes[updateType]}
                    onChange={handleUpdateTypesChangesChange}
                    name={updateType}
                    color="primary"
                  />
                }
                label={UPDATE_TYPE_LABELS[updateType]}
              />
            ))}
          </Paper>
        </Grid>
        <Grid item sm={6}>
          {loading && !data ? (
            <div style={{ padding: "1em", margin: "1em", textAlign: "center" }}>
              <CircularProgress />
            </div>
          ) : (
            data?.statuses.map((update) => (
              <Paper
                square
                key={update.id}
                style={{ padding: "1em", margin: "1em" }}
              >
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div
                    style={{
                      fontSize: 16,
                      fontWeight: "bold",
                      textAlign: "left",
                    }}
                  >
                    {moment(new Date(update.updatedAt)).format("DD/MM/YYYY")}{" "}
                    {update.type === "NAME"
                      ? "Har skiftet navn"
                      : update.type === "STATUS"
                      ? "Har skiftet status"
                      : update.type === "NOOWNER"
                      ? "Har ingen reelle ejere registreret"
                      : update.type === "NEWCOMPANY"
                      ? "Ny virksomhed"
                      : update.type === "PERSONS"
                      ? "Person ændringer"
                      : "Har skiftet adresse"}
                  </div>
                  <div style={{ textAlign: "right" }}>
                    <a
                      href={`https://datacvr.virk.dk/enhed/virksomhed/${update.companyId}`}
                      target="_blank"
                      style={{ color: "#000" }}
                      rel="noreferrer"
                    >
                      Se på CVR
                    </a>
                  </div>
                </div>
                <div style={{ fontSize: 16 }}>
                  <span style={{ fontWeight: "bold" }}>
                    {update.company.name}
                  </span>
                </div>
                <div style={{ fontSize: 16 }}>
                  <span style={{ fontWeight: "bold" }}>CVR-nr: </span>
                  {update.companyId}
                </div>
                <br />
                {update.type === "NAME" ? (
                  <span>
                    Har skiftet navn fra "{update.oldValue}" til "
                    {update.newValue}"
                  </span>
                ) : update.type === "STATUS" ? (
                  <span>
                    Har skiftet status fra "{update.oldValue}" til "
                    {update.newValue}"
                  </span>
                ) : update.type === "NOOWNER" ? (
                  <span>Har ingen reelle ejere registreret</span>
                ) : update.type === UpdateType.NEWCOMPANY ? (
                  <span>
                    {console.log(
                      groupNamesByFunction(update.newValue as UpdateData)
                    )}
                  </span>
                ) : update.type === UpdateType.PERSONS ? (
                  <Persons
                    persons={JSON.parse(update.newValue) as PersonChange[]}
                  />
                ) : (
                  <span>
                    Har skiftet adresse fra "{update.oldValue}" til "
                    {update.newValue}"
                  </span>
                )}
              </Paper>
            ))
          )}
          <Stack horizontal tokens={stackTokens} horizontalAlign="center">
            <DefaultButton
              disabled={!skip}
              text="Forrige"
              onClick={handlePrevClick}
            />
            <DefaultButton
              text="Næste"
              onClick={handleNextClick}
              disabled={skip + take >= (data?.count || 0)}
            />
          </Stack>
          <br />
        </Grid>
        <Grid item sm={3}></Grid>
      </Grid>
    </div>
  );
};
