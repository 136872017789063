interface CompanyData {
  name: string;
  functions: string[];
}

interface GroupedCompanyData {
  [functionName: string]: string[];
}

export interface UpdateData {
  name: string;
  companyData: CompanyData[];
}

export function groupNamesByFunction(company: UpdateData): GroupedCompanyData {
  const groupedData: GroupedCompanyData = {};

  company.companyData?.forEach((data) => {
    data.functions.forEach((func) => {
      if (!groupedData[func]) {
        groupedData[func] = [];
      }
      groupedData[func].push(data.name);
    });
  });

  return groupedData;
}
